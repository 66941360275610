var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("Логи действий")]),_c('logs-filters',{staticClass:"mb-5",attrs:{"table-params":_vm.tableParams},on:{"change-field":_vm.changeField}}),_c('data-table',{attrs:{"url":"logs","headers":_vm.headers,"table-params":_vm.tableParams,"clickable-rows":""},on:{"click:row":_vm.showLog},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var log = ref.item;
return [_c('nobr',[_vm._v(_vm._s(log.action))])]}},{key:"item.userName",fn:function(ref){
var log = ref.item;
return [_c('router-link',{staticClass:"nobr",attrs:{"to":{ name: 'users-id', params: { id: log.userId } }}},[_vm._v(" "+_vm._s(log.userName || "не указано")+" ")])]}},{key:"item.userRole",fn:function(ref){
var log = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatRole(log))+" ")]}},{key:"item.statusCode",fn:function(ref){
var log = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.pickHttpStatusColor(log)}},[_vm._v(" "+_vm._s(log.statusCode)+" ")])]}},{key:"item.createdAt",fn:function(ref){
var log = ref.item;
return [_c('nobr',[_vm._v(_vm._s(_vm._f("dateTime")(log.createdAt)))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }